import * as React from "react";
import { Link } from "gatsby";

import logo from "../img/yamakawa-full.png";
import qiita from "../img/social/qiita_org.png";
import github from "../img/social/github_alt.svg";
import twitter from "../img/social/twitter_alt.svg";
import LinkedIn from "../img/social/linkedin_alt.svg";
import Wantedly from "../img/social/Wantedly_org.png";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="Yosuke"
            style={{ width: "224px", height: "91px" }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link to="/" className="navbar-item">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="navbar-item" to="/products">
                        Products
                      </Link>
                    </li> */}
                    {/* <li>
                      <a
                        className="navbar-item"
                        href="/admin/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Admin
                      </a>
                    </li> */}
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="Qiita" href="https://qiita.com/honahuku">
                  <img
                    src={qiita}
                    alt="Qiita"
                    style={{ width: "16px", height: "16px" }}
                  />
                </a>
                <a title="Twitter" href="https://twitter.com/yosuke_yamakawa">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="GitHub" href="https://github.com/Honahuku">
                  <img
                    src={github}
                    alt="GitHub"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="LinkedIn" href="https://www.linkedin.com/in/yosuke-yamakawa/">
                  <img
                    src={LinkedIn}
                    alt="LinkedIn"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="Wantedly" href="https://www.wantedly.com/id/yosuke_yamakawa">
                  <img
                    src={Wantedly}
                    alt="Wantedly"
                    style={{ width: "16px", height: "13px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
